
import { Component, Vue } from 'vue-property-decorator';

// Utilities
import Title from '@/components/common/Title.vue';
import { OnboardingSteps, VerificationCodeDisplay } from '@/components/onboarding';
import OnboardLayout from '@/components/OnboardLayout.vue';
import { getHomeRoute } from '@/router/routes';
import { AuthService, UserService } from '@/services';
import { UserModule } from '@/store/modules';

// Types
import { IForgotPassword, VerificationCodeType } from '@/types/auth.types';
import { IAuthUser } from '@/types/user.types';

@Component({
  components: { OnboardLayout, OnboardingSteps, Title, VerificationCodeDisplay }
})
export default class AccountVerification extends Vue {
  sending = true;
  timerEnabled = false;
  timer = 60;
  shouldVerifyButtonEnabled = true;

  verificationCode = '';

  forgot: IForgotPassword = { email: this.$route.query.email as string };

  /** If `email` is passed in the url query string, the user is in the
   * password reset flow. Otherwise, fallback to the account verification flow.
   */
  get verificationType() {
    return this.forgot.email
      ? VerificationCodeType.PASSWORD_RESET
      : VerificationCodeType.ACCOUNT_VERIFICATION;
  }

  get showEditEmail() {
    return this.verificationType === VerificationCodeType.ACCOUNT_VERIFICATION;
  }

  get titleAndEmail() {
    return this.verificationType === VerificationCodeType.PASSWORD_RESET
      ? {
          title: 'Check your email',
          subtitle: 'We’ve sent you an email with the instructions to reset your password',
          email: this.forgot.email
        }
      : {
          title: 'Verify your email',
          subtitle: 'Please check your email for validating your email address',
          email: this.user?.email
        };
  }

  handleCodeClick() {
    if (this.$refs.hiddenCodeInput) {
      (this.$refs.hiddenCodeInput as HTMLElement).focus();
    }
  }

  checkLength(e: any): void {
    if (e.target.value.length >= 5) {
      this.shouldVerifyButtonEnabled = false;
    } else {
      this.shouldVerifyButtonEnabled = true;
    }
  }

  /** Authenticated user */
  get user(): IAuthUser | null {
    return UserModule.authenticatedUser;
  }

  timerCountDown(): void {
    if (this.timer > 0) {
      this.timerEnabled = true;
      setTimeout(() => {
        this.timer--;
        this.timerCountDown();
      }, 1000);
    } else {
      this.timerEnabled = false;
      this.timer = 60;
    }
  }

  async resendEmail(): Promise<void> {
    try {
      if (this.timerEnabled) return;

      this.timerCountDown();
      await AuthService.sendVerifyAccountEmail();
    } catch (e: any) {
      const message = this.$getError(e, 'Could not send an email with an account verification code');
      this.$notifyError(message, true);
    }
  }

  async editEmail(): Promise<void> {
    try {
      this.$router.replace('/edit-email');
    } catch (e: any) {
      this.$notifyError(this.$getError(e));
    }
  }

  get image(): string {
    return require(`@/assets/images/opened-letter.png`);
  }

  async beforeCreate(): Promise<void> {
    const user = await UserService.fetchUser();
    if (user.verifiedAt) {
      this.$router.replace(getHomeRoute());
      return;
    }
  }

  async verifyAccount() {
    if (!this.user) {
      this.$router.replace('/register');
      const message = this.$getError(
        'If you are completing registration, please make sure to continue in the same browser. Otherwise, restart the registration.'
      );
      this.$notifyError(message, true);
      return;
    }

    this.verificationCode = this.verificationCode.slice(0, 5);
    try {
      await AuthService.verifyUser(this.verificationCode, this.user.email);
      this.user.verifiedAt = new Date();
      UserModule.setUser(this.user);

      this.$notifySuccess('Account verified', true);

      /* During onboarding, the user was "logged in" (that is the auth tokens 
      were provided, but the user is only allowed to do onboarding) in order to 
      update their account (to add a card, to set the notification preferences,
      to set the account as verifieed).
      Now the user can access the pages that require authorization.
       */
      this.$router.replace(getHomeRoute());
    } catch (e: any) {
      const message = this.$getError(e, 'Could not activate account');
      this.$notifyError(message, true);
    }
  }
}
